import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';

import MainLayout from "components/Layout/MainLayout";
import { I_HotelProduct } from "etc/types";
import { getHotelProductList } from "api/hotel";
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';
import { EHotelProductSaleType, EHotelProductType } from "etc/enum";

import StatusButton from 'components/Button/StatusButton';
import DetailButton from "components/Button/DetailButton";

function HotelProductList() {
  
  const navigate = useNavigate();
  const Logout = useLogout();

  const { hotelId } = useParams<{ hotelId : string }>();
  const [ isList, setList] = useState<I_HotelProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAsyncList();
  }, []);

  const getAsyncList = async () => {
    
    if (!hotelId) {
      console.error("!hotelId ");
      setLoading(false);
      return;
    }
    
    const data = await getHotelProductList(
      hotelId,
    );

    if (data.ok) {
      
      setList(data.res.list);

    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
    setLoading(false);
  };

  const handleEdit = (hotelProductId: number) => {
    navigate(`/hotel/${hotelId}/product/${hotelProductId}`); // 수정 페이지로 이동
  };

  const getProductTypeText = (type: EHotelProductType): string => {
    switch (type) {
      case EHotelProductType.HOTELING:
        return '회원권 호텔 상품';
      case EHotelProductType.DAYCARE:
        return '회원권 유치원 상품';
      case EHotelProductType.TIMECARE:
        return '시간권 상품';
      case EHotelProductType.ONEDAY:
        return '1일권 유치원 상품';
      case EHotelProductType.ONEDAY_HOTELING:
        return '1일권 호텔링 상품';
      default:
        return '알 수 없는 상품 유형';
    }
  };

  const getProductSaleTypeText = (type: EHotelProductSaleType): string => {
    console.log('type : ' ,type)
    switch (type) {
      case EHotelProductSaleType.SALE:
        return '판매 상품';
      case EHotelProductSaleType.SERVICE:
        return '서비스 상품';
    }
  };
  
  const handleStatusToggle = (id: number, currentStatus: string) => {
    const newStatus = currentStatus === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE';
    // Call API to update status here
    console.log(`Toggling status for ID: ${id} to ${newStatus}`);
  };
  
  const handleRegister = () => {
    if (hotelId) {
      navigate(`/hotel/${hotelId}/product/register`); // 등록 페이지로 이동
    }
  };

  return (
    
    <MainLayout>
      {
      loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Container>
        <Header>
          <Title>호텔 상품 리스트</Title>
          <RegisterButton onClick={handleRegister}>상품등록</RegisterButton>
        </Header>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>상품명</Th>
              {/* <Th>내용</Th> */}
              {/* <Th>설명</Th> */}
              <Th>가격</Th>
              <Th>원가</Th>
              <Th>시간당 가격</Th>
              <Th>수량</Th>
              <Th>할인율</Th>
              <Th>유효기간</Th>
              <Th>타입</Th>
              <Th>판매타입</Th>
              <Th>상태</Th>
              <Th>관리</Th>
            </tr>
          </thead>
          <tbody>
            {isList.map((product) => (
              <Tr key={product.id}>
                <Td>{product.id}</Td>
                <Td>{product.title}</Td>
                {/* <Td>{product.content}</Td> */}
                {/* <Td>{product.description}</Td> */}
                <Td>{product.price.toLocaleString()}원</Td>
                <Td>{product.originPrice?.toLocaleString()}원</Td>
                <Td>{product.hourlyPrice && product.hourlyPrice > 0 ? `${product.hourlyPrice?.toLocaleString()}원` : ""}</Td>
                <Td>{product.quantity}</Td>
                <Td>{product.salePercent}%</Td>
                <Td>{product.validityPeriod}</Td>
                <Td>{getProductTypeText(product.hotelProductType)}</Td>
                <Td>{getProductSaleTypeText(product.hotelProductServiceType)}</Td>
                <Td>
                  <StatusButton
                    isActive={product.hotelProductStatus === 'ACTIVE'}
                    onClick={() => handleStatusToggle(product.id, product.hotelProductStatus)}
                  >
                    {product.hotelProductStatus}
                  </StatusButton>
                </Td>
                <Td>
                  <DetailButton onClick={() => handleEdit(product.id)}>수정</DetailButton>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
      )}
    </MainLayout>
  );
}

export default HotelProductList;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #555;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const RegisterButton = styled.button`
  background-color: ${(props) => props.theme.colors.ho1};
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }
`;

// const EditButton = styled.button`
//   background-color: ${(props) => props.theme.colors.ho1}; /* 테마 색상 사용 */
//   color: #fff;
//   border: none;
//   padding: 5px 10px;
//   border-radius: 5px;
//   cursor: pointer;

//   &:hover {
//     background-color: ${(props) => props.theme.colors.ho2};
//   }
// `;
