import { callApi } from './callApi';

// 문의하기 리스트
export const getInquire = async (
  limit: number,
  currentPage: number,
  status: string
) => {
  const res = await callApi({
    url: `/admin/inquire?limit=${limit}&currentPage=${currentPage}&status=${status}`,
    method: 'GET',
  });
  return res;
};

// 문의하기 상세
export const getInquireDetail = async (id: number) => {
  const res = await callApi({
    url: `/admin/inquire/detail/${id}`,
    method: 'GET',
  });
  return res;
};

// 답변 상태 변경
export const postInquireStatus = async (id: number, status: string, answer: string, userId: string) => {
  const res = await callApi({
    url: `/admin/inquire/status`,
    method: 'POST',
    data: { id: id, status: status, answer: answer, userId: userId  },
  });
  return res;
};
