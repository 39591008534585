import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';
import NormalModal from 'components/Modal/NormalModal';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import StorePurchaseTableItem from 'components/Item/StorePurchaseTableItem';
import PagenationButton from 'components/Button/PagenationButton';
import PetTableItem from 'components/Item/PetTableItem';
import PointHistoryModal from 'components/Modal/PointHistoryModal';

import {
  getUserDetail,
  getUserPet,
  getUserWithdrawReason,
  patchUserBen,
} from 'api/user';
import useLogout from 'hooks/useLogout';
import { I_UserDetail, I_UserOrder, I_UserPet } from 'etc/types';
import { numberFormat } from 'utils/common';
import { getUserOrderList } from 'api/order';
import HotelProductForm from 'components/Hotel/UserHotelProductAssign';
import UserHotelProductAssign from 'components/Hotel/UserHotelProductAssign';

function UserDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isSanctionwModalOpen, setIsSanctionwModalOpen] =
    useState<boolean>(false);
  const [isStoreModalOpen, setIsStoreModalOpen] = useState<boolean>(false);
  const [isPetModalOpen, setIsPetModalOpen] = useState<boolean>(false);
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] =
    useState<boolean>(false);
  const [isPointModalOpen, setIsPointModalOpen] = useState<boolean>(false);
  const [storeTotalCount, setStoreTotalCount] = useState<number>(0); // 스토어 구매 내역 전체 개수
  const [storeActivePage, setStoreActivePage] = useState<number>(1); // 스토어 구매 내역 현재 페이지
  const [user, setUser] = useState<I_UserDetail>(userReset);
  const [withdrawReason, setWithdrawReason] = useState<string>('');
  const [petList, setPetList] = useState<I_UserPet[]>([]);
  const [orderList, setOrderList] = useState<I_UserOrder[]>([]);

  const handleStorePageChange = (page: number) => {
    setStoreActivePage(page);

    getAsyncOrderList(page);
  };

  const onClickSanctionModalOpen = () => {
    setIsSanctionwModalOpen(true);
  };

  const onClickStoreModalOpen = async () => {
    setIsStoreModalOpen(true);

    getAsyncOrderList(1);
  };

  const onCloseStoreModal = () => {
    setIsStoreModalOpen(false);
    setStoreActivePage(1);
  };

  const onClickPetModal = async () => {
    const data = await getUserPet(Number(id));

    if (data.ok) {
      if (data.res.item.length === 0) {
        alert('등록된 반려동물이 없습니다.');
      } else {
        setIsPetModalOpen(true);
        setPetList(data.res.item);
        // console.log(data.res.item);
      }
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const onClickSanction = async () => {
    const data = await patchUserBen(Number(id));

    if (data.ok) {
      alert('영구 활동 제재 되었습니다.');
      navigate('/user');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const onClickWithdrawalReason = async () => {
    if (user.user.userStatus === 'Withdrawal') {
      const data = await getUserWithdrawReason(Number(id));

      if (data.ok) {
        setIsWithdrawalModalOpen(true);
        setWithdrawReason(data.res.reason);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncOrderList = async (page: number) => {
    const data = await getUserOrderList(Number(id), page, 10);

    if (data.ok) {
      // console.log(data.res);
      setOrderList(data.res.item);
      setStoreTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncUser = async () => {
    const data = await getUserDetail(Number(id));

    if (data.ok) {
      // console.log(data.res);
      setUser(data.res);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const openHotelProductAssignWindow = () => {
    const url = `/hotel/1/product-gift?userId=${id}`; // Pass hotelId and userId
    const options =
      'width=500,height=600,scrollbars=yes,resizable=yes,top=100,left=100';
    window.open(url, 'HotelProductGift', options);
  };
  
  useEffect(() => {
    getAsyncUser();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>기본 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>가입자 명</th>
              <td>
                <DisabledInput text={user.user?.name} />
              </td>
              <th>활동 현황</th>
              <td
                onClick={() => onClickWithdrawalReason()}
                style={{
                  color:
                    user.user.userStatus === 'Active' ? '#13A872' : '#FC1D1D',
                }}
              >
                <DisabledInput
                  style={{
                    textDecoration:
                      user.user.userStatus === 'Withdrawal' ? 'underline' : '',
                    cursor:
                      user.user.userStatus === 'Withdrawal' ? 'pointer' : '',
                  }}
                  text={
                    user.user.userStatus === 'Active'
                      ? '활동'
                      : user.user.userStatus === 'Restriction'
                      ? '제재'
                      : user.user.userStatus === 'Withdrawal'
                      ? '탈퇴'
                      : '정지'
                  }
                />
              </td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td>
                <DisabledInput text={user?.user?.profile?.nickname} />
              </td>
              <th>등록 반려동물</th>
              <td>
                <NormalButton
                  onClick={() => onClickPetModal()}
                  style={{ textDecoration: 'underline' }}
                >
                  반려동물 정보 상세 보기
                </NormalButton>
              </td>
            </tr>
            <tr>
              <th>User code</th>
              <td>
                <DisabledInput text={user.user.userCode} />
              </td>
              <th>가입방식</th>
              <td>
                <DisabledInput
                  text={
                    user.user.provider === 'Local'
                      ? '이메일'
                      : user.user.provider === 'Naver'
                      ? '네이버'
                      : user.user.provider === 'Kakao'
                      ? '카카오'
                      : user.user.provider === 'Google'
                      ? '구글'
                      : '애플'
                  }
                />
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <DisabledInput text={user.user?.email} />
              </td>
              <th>휴대번호</th>
              <td>
                <DisabledInput text={user.user?.phone} />
              </td>
            </tr>
            <tr>
              <th>등록지역</th>
              <td>
                <DisabledInput
                  text={
                    user.user?.user_address?.address
                      ? user.user?.user_address?.address +
                        ' ' +
                        user.user?.user_address?.addressDetail
                      : ''
                  }
                />
              </td>
              <th>생년월일</th>
              <td>
                <DisabledInput text={user.user?.birth} />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_Section>
        <S_Title>활동 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>
                돌보미
                <br />
                활동 여부
              </th>
              <td>
                <DisabledInput
                  text={
                    !user.caringProfile
                      ? ''
                      : user.caringProfile?.caringStatus === 'Active'
                      ? '활동 중'
                      : '비활성화'
                  }
                />
              </td>
              <td colSpan={2}>
                {user.caringProfile && (
                  <NormalButton
                    onClick={() => navigate(`/user/care/${user.user.id}`)}
                    style={{ textDecoration: 'underline' }}
                  >
                    돌보미 상세 정보
                  </NormalButton>
                )}
              </td>
            </tr>
            <tr>
              <th>신고한 횟수</th>
              <td>
                <DisabledInput text={user?.reportCount} />
              </td>
              <th>신고 받은 횟수</th>
              <td>
                <DisabledInput text={user?.getReportCount} />
              </td>
            </tr>
            <tr>
              <th>활동 제재</th>
              <td>
                <DisabledInput
                  text={user.user.sanctionCount}
                  style={{ color: '#FC1D1D' }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>획득 포인트</th>
              <td>
                <DisabledInput text={numberFormat(user.point?.point)} />
              </td>
              <th className='alignTop'>사용 포인트</th>
              <td>
                <DisabledInput text={numberFormat(user.point?.usedPoint)} />
              </td>
            </tr>
          </tbody>
        </S_Table>
        <S_ButtonBox>
          <NormalButton
            onClick={() => setIsPointModalOpen(true)}
            style={{ textDecoration: 'underline' }}
          >
            포인트 상세 내역
          </NormalButton>
        </S_ButtonBox>
      </S_Section>
      <S_Section>
        <S_Title>구매 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th style={{ width: 100 }}>스토어 구매 내역</th>
              <td>
                <NormalButton
                  onClick={() => onClickStoreModalOpen()}
                  style={{ textDecoration: 'underline' }}
                >
                  상세 정보
                </NormalButton>
              </td>
            </tr>
          </tbody>
        </S_Table>
      
      </S_Section>
      
      <S_Section>
        <S_Title>호텔</S_Title>
        <LongButton
          onClick={openHotelProductAssignWindow}
          style={{ backgroundColor: '#13A872' }}
        >
          호텔 상품 선물하기
        </LongButton>
        <S_ButtonBox style={{ marginTop: 30 }}>
          <LongButton
            onClick={() => onClickSanctionModalOpen()}
            style={{ backgroundColor: '#FC1D1D' }}
          >
            영구 활동 제재
          </LongButton>
        </S_ButtonBox>
      </S_Section>

      {/* 반려동물 정보 상세 모달 */}
      {isPetModalOpen && (
        <NormalModal
          state={isPetModalOpen}
          setState={setIsPetModalOpen}
          title='등록 반려동물 정보'
        >
          <S_PetModalContent>
            <S_PetCount>
              총 등록 반려동물 수
              <DisabledInput text={petList.length} style={{ width: 380 }} />
            </S_PetCount>
            <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 16 }}>
              반려동물 상세 정보
            </p>
            {petList.map((v: I_UserPet, i: number) => (
              <S_PetTable key={i}>
                <tbody>
                  <PetTableItem item={v} />
                </tbody>
              </S_PetTable>
            ))}
          </S_PetModalContent>
        </NormalModal>
      )}

      {/* 활동 제재 모달 */}
      {isSanctionwModalOpen && (
        <NormalModal
          state={isSanctionwModalOpen}
          setState={setIsSanctionwModalOpen}
          title='영구 활동 제재하시겠습니까?'
        >
          <S_SanctionModalContent>
            <p>확인 시 해당 내용은 철회할 수 없습니다.</p>
            <NormalButton
              onClick={() => onClickSanction()}
              style={{ backgroundColor: '#FC1D1D' }}
            >
              확인
            </NormalButton>
          </S_SanctionModalContent>
        </NormalModal>
      )}

      {/* 스토어 구매 내역 모달 */}
      {isStoreModalOpen && (
        <NormalModal
          state={isStoreModalOpen}
          setState={setIsStoreModalOpen}
          title='스토어 구매 내역'
          onClose={() => onCloseStoreModal()}
        >
          <S_StoreModalContent>
            <S_TableContainer>
              <S_TableBox>
                <S_StoreTable>
                  <thead>
                    <tr>
                      {TABLE_HEADER_STORE.map((v, i) => (
                        <S_TableHeaderItem key={i} style={{ minWidth: 100 }}>
                          {v}
                        </S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {orderList.map((v: I_UserOrder, i: number) => (
                      <StorePurchaseTableItem key={i} item={v} />
                    ))}
                    <tr>
                      {orderList.length === 0 && (
                        <S_DataNone colSpan={4}>
                          구매 내역이 없습니다.
                        </S_DataNone>
                      )}
                    </tr>
                  </tbody>
                </S_StoreTable>
              </S_TableBox>
              <div style={{ marginTop: -30 }}>
                <PagenationButton
                  activePage={storeActivePage}
                  totalCount={storeTotalCount}
                  postPerPage={10}
                  handlePageChange={handleStorePageChange}
                />
              </div>
            </S_TableContainer>
          </S_StoreModalContent>
        </NormalModal>
      )}

      {/* 탈퇴사유 모달 */}
      {isWithdrawalModalOpen && (
        <NormalModal
          state={isWithdrawalModalOpen}
          setState={setIsWithdrawalModalOpen}
          title='탈퇴 사유'
        >
          <S_WithdrawModalContent>{withdrawReason}</S_WithdrawModalContent>
        </NormalModal>
      )}

      {/* 포인트 내역 모달 */}
      {isPointModalOpen && (
        <PointHistoryModal
          isOpen={isPointModalOpen}
          onClose={() => setIsPointModalOpen(false)}
          id={Number(id)}
        />
      )}
    </MainLayout>
  );
}

export default UserDetail;

const S_DataNone = styled.td`
  padding: 20px 0;
  font-size: 14px;
  text-align: center;
`;

const S_WithdrawModalContent = styled.div`
  min-width: 400px;
  padding: 30px 30px 60px;
`;

const S_PetTable = styled.table`
  width: 100%;
  & + & {
    margin-top: 40px;
  }

  th {
    min-width: 120px;
    font-size: 13px;
    font-weight: 500;
  }
  td {
    min-width: 310px;
  }
`;

const S_PetCount = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 44px;
  font-weight: 500;
`;

const S_PetModalContent = styled.div`
  max-height: 700px;
  padding: 50px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_StoreTable = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_StoreModalContent = styled.div`
  padding: 16px 72px 60px 72px;
`;

const S_SanctionModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 25px 125px 50px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    vertical-align: middle;

    &:nth-child(1) {
      min-width: 85px;
    }
    &:nth-child(3) {
      min-width: 120px;
      padding-left: 20px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;
const S_ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }

  td > div {
    min-width: 380px;
  }
`;

const TABLE_HEADER_STORE = [
  '주문 번호',
  '주문 일시',
  '주문 상품',
  '총 결제 금액',
];

const userReset = {
  user: {
    id: 0,
    createdAt: '',
    updatedAt: '',
    email: '',
    reportCount: 0,
    userCode: '',
    provider: '',
    phone: '',
    name: '',
    birth: '',
    userStatus: '',
    paid: false,
    profile: { userId: 0, nickname: '', career: 0 },
    user_address: { userId: 0, address: '', addressDetail: '' },
    sanctionCount: 0,
  },
  caringProfile: { caringStatus: '' },
  getReportCount: 0,
  reportCount: 0,
  point: {
    id: 0,
    point: 0,
    usedPoint: 0,
  },
};
