import System from 'assets/image/perm-identity.svg';
import Wifi from 'assets/image/wifi.svg';
import Card from 'assets/image/card-giftcard.svg';
import Setting from 'assets/image/settings.svg';
import Hotel from 'assets/image/hotel.svg';
import Hotel02 from 'assets/image/hotel02.svg';
import Hotel03 from 'assets/image/hotel03.svg';
import Petstival from 'assets/image/petstival.svg';
import Travel from 'assets/image/travelling.svg';

// sidebar menu
export const SIDE_BAR_DATA = [
  {
    title: '시스템 관리',
    icon: System,
    submenu: [{ title: '관리자 정보 관리', pathname: '/admin' }],
  },
  {
    title: '서비스 관리',
    icon: Wifi,
    submenu: [
      {
        title: '유저 관리',
        submenu: [
          { title: '유저 정보', pathname: '/user' },
          { title: '돌보미 유저 정보', pathname: '/user/care' },
          { title: '돌보미 신청 관리', pathname: '/user/care/apply' },
        ],
      },
      { title: '신고 관리', pathname: '/service/report' },
      { title: '서비스 문의', pathname: '/service/app' },
      {
        title: '서비스 컨텐츠 관리',
        submenu: [
          { title: '서비스 안내 정보 관리', pathname: '/service/guide' },
          { title: '품종별 정보 관리', pathname: '/service/kind' },
          { title: '이벤트 관리', pathname: '/service/event' },
        ],
      },
      {
        title: '서비스 운영 관리',
        submenu: [
          { title: '공지사항 관리', pathname: '/service/notice' },
          { title: '포인트 정책 관리', pathname: '/service/point' },
          // { title: '서비스 정책 관리', pathname: '/service/policy' },
          { title: '채팅 관리', pathname: '/service/chatting' },
        ],
      },
    ],
  },
  {
    title: '스토어 관리',
    icon: Card,
    submenu: [
      { title: '상품 관리', pathname: '/store/product' },
      { title: '주문 관리', pathname: '/store/order' },
      { title: '배송 관리', pathname: '/store/delivery' },
      { title: '취소 관리', pathname: '/store/cancel' },
      { title: '교환 관리', pathname: '/store/exchange' },
      { title: '반품 관리', pathname: '/store/return' },
      { title: '구매 확정 관리', pathname: '/store/confirm' },
      {
        title: '고객 관리',
        submenu: [
          { title: '스토어 문의', pathname: '/store/inquiry' },
          { title: '스토어 리뷰', pathname: '/store/review' },
        ],
      },
      { title: '쿠폰 발급 관리', pathname: '/store/coupon' },
    ],
  },
  {
    title: '펫스티벌',
    icon: Petstival,
    submenu: [
      {
        title: '펫스티벌 관리',
        submenu: [
          { title: '리스트', pathname: '/petstival/list' },
          { title: '월별 랭킹', pathname: '/petstival/rankingList' },
        ],
      },
    ],
  },
  {
    title: '여행상품',
    icon: Travel,
    submenu: [
      {
        title: '여행상품 관리',
        submenu: [
          { title: '카테고리', pathname: '/travelProduct/category' },
          { title: '여행상품 등록', pathname: '/travelProduct/register' },
          { title: '리스트', pathname: '/travelProduct/list' },
        ],
      },
    ],
  },
  {
    title: '호텔링 관리',
    icon: Hotel03,
    submenu: [
      {
        title: '상품 관리',
        submenu: [
          { title: '상품 등록', pathname: '/hotel/1/product/register' },
          { title: '상품 리스트', pathname: '/hotel/1/product/list' },
        ],
      },
      {
        title: '예약',
        submenu: [
          { title: '예약 일정 관리', pathname: '/hotel/1/reservation' },
          { title: '영업불가 날짜 설정', pathname: '/hotel/1/disableDate' },
        ],
      },
      {
        title: '결제',
        submenu: [
          { title: '회원권 결제내역', pathname: '/hotel/1/membership-log' },
          { title: '유치원/시간권 결제내역', pathname: '/hotel/1/oneday-log' },
        ],
      },
      // { title: '영업점 관리', pathname: '/hotel/list' },
      // { title: '취소 관리', pathname: '/store/cancel' },
      // { title: '구매 확정 관리', pathname: '/store/confirm' },
      // { title: '쿠폰 발급 관리', pathname: '/store/coupon' },
    ],
  },
  {
    title: '마케팅',
    icon: Setting,
    submenu: [
      {
        title: '파이어베이스',
        submenu: [
          { title: '파이어베이스 연결', pathname: '/app/firebase' },
        ]
      },
      {
        title: 'UTM',
        submenu: [
          { title: 'UTM 등록', pathname: '/marketing/utm/register' },
          { title: 'UTM 리스트', pathname: '/marketing/utm/list' },
          { title: 'UTM 차트', pathname: '/marketing/utm/chart' },
        ]
      },
      {
        title: '푸시 메세지 관리',
        submenu: [
          { title: '전송', pathname: '/fcm/send' },
        ],
      },
    ],
  },
  
];
