import React from "react";
import styled from "styled-components";

interface EditButtonProps {
  onClick: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  [key: string]: any;
}

const DeatilButton: React.FC<EditButtonProps> = ({ onClick, children = "수정", style, ...rest }) => {
  return (
    <S_DeatilButton onClick={onClick} style={style} {...rest}>
      {children}
    </S_DeatilButton>
  );
};

export default DeatilButton;

const S_DeatilButton = styled.button`
  background-color: ${(props) => props.theme.colors.ho1};
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }
`;
