import { EHotelProductSaleType, EHotelProductType } from 'etc/enum';
import { I_HotelProduct, I_User } from 'etc/types';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { getHotelProductList, postHotelProductGift } from "api/hotel";
import { getUserDetail } from "api/user";
import useLogout from 'hooks/useLogout';


function HotelProductGift() {

  const Logout = useLogout();

  const { hotelId } = useParams<{ hotelId: string }>();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');

  const [ isList, setList ] = useState<I_HotelProduct[]>([]);
  const [ isUser, setUser ] = useState<I_User>();
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<I_HotelProduct[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAsyncUser = async () => {
    
    if (!userId) {
      console.error("!userId ");
      setLoading(false);
      return;
    }
    
    const response = await getUserDetail(Number(userId));

    console.log('response : ', response)
    if (response.ok) {
      
      setUser(response.res.user);

    } else {
      if (response.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(response.res.error.message);
      }
    }
    setLoading(false);
  };

  const getAsyncList = async () => {
    
    if (!hotelId) {
      console.error("!hotelId ");
      setLoading(false);
      return;
    }
    
    const response = await getHotelProductList(
      hotelId,
      EHotelProductSaleType.SERVICE
    );

    if (response.ok) {
      
      setList(response.res.list);

    } else {
      if (response.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(response.res.error.message);
      }
    }
    setLoading(false);
  };
  // 선물하기 버튼 클릭 핸들러
  const handleGiftProduct = async () => {

    if (!userId) {
      alert('userId를 확인해주세요.');
      return;
    }

    if (!selectedProductId) {
      alert('선물할 상품을 선택해주세요.');
      return;
    }
    // setIsLoading(true);  // 로딩 상태 시작

    const selectedProduct = isList.find(product => product.id === selectedProductId);
    if (!selectedProduct) {
      alert('선택한 상품 정보를 찾을 수 없습니다.');
      return;
    }

    // return;
    try {
      const response = await postHotelProductGift(hotelId as string, {
        hotelProductId: selectedProductId.toString(),
        userId: userId,
      });

      console.log('response : ', response)
      if (response.ok) {
        alert(`유저 ID: ${userId}에게 상품 ID: ${selectedProduct.title}를 성공적으로 선물했습니다.`);
      } else {
        if (response.res.status === 315) {
          Logout();  // 인증 에러 시 로그아웃
        } else {
          alert(`선물하기에 실패했습니다. 오류: ${response.res.error.message}`);
          console.error(response.res.error);
        }
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      alert('오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setIsLoading(false);  // 로딩 상태 종료
    }

  };

  const formatDescription = (description: string) => {
    return description.split('\n').map((line, index) => {
      if (line.includes('||')) {
        const [left, right] = line.split('||');
        return (
          <div key={index} className="split-text">
            <span>{left}</span>
            <span>{right}</span>
          </div>
        );
      } else {
        return (
          <div key={index} className="line-break">
            {line}
          </div>
        );
      }
    });
  };
  

  const handleSelectProduct = (productId: number) => {
    setSelectedProductId(productId);
  };
  
  useEffect(() => {
    getAsyncUser();
    getAsyncList();
  }, []);

  return (
    <Container>
      <Title>호텔 상품 선물하기</Title>
      {isLoading ? (
        <LoadingMessage>상품 로딩 중...</LoadingMessage>
      ) : (
        <>
        {/* 유저 정보 */}
          {
            <UserInfo>
              <UserInfoTitle>선물할 회원 정보</UserInfoTitle>
              <UserInfoItem>
                <span>유저 ID:</span> {isUser?.id}
              </UserInfoItem>
              <UserInfoItem>
                <span>Email:</span> {isUser?.email || '없음'}
              </UserInfoItem>
              <UserInfoItem>
                <span>Phone:</span> {isUser?.phone || '없음'}
              </UserInfoItem>
              <UserInfoItem>
                <span>이름:</span> {isUser?.name || '없음'}
              </UserInfoItem>
              <UserInfoItem>
                <span>닉네임:</span> {isUser?.profile?.nickname || '없음'}
              </UserInfoItem>
            </UserInfo>
          }
          {/* 상품 리스트 */}
          <ProductList>
            {isList.map((product) => (
              <ProductItem
                key={product.id}
                onClick={() => handleSelectProduct(product.id)}
                className={selectedProductId === product.id ? 'selected' : ''}
              >
                <input
                  type="radio"
                  name="product"
                  value={product.id}
                  checked={selectedProductId === product.id}
                  readOnly
                />
                <label>
                  {product.title} - {product.price.toLocaleString()}원 (
                  {product.salePercent}% 할인)
                  <ProductDescription>{formatDescription(product.description ?? '')}</ProductDescription>
                </label>
              </ProductItem>
            ))}
          </ProductList>
          { isUser &&
            <GiftButton onClick={handleGiftProduct}>선물하기</GiftButton>
          }
        </>
      )}
    </Container>
  );
}

export default HotelProductGift;

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const UserInfo = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const UserInfoTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const UserInfoItem = styled.div`
  font-size: 14px;
  margin-bottom: 8px;

  span {
    font-weight: bold;
  }
`;

const ProductList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
`;

const ProductItem = styled.li`
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  gap: 20px; /* input과 label 사이 간격 추가 */

  cursor: pointer; /* 전체 항목 클릭 가능 */

  input {
    flex-shrink: 0; /* 크기가 줄어들지 않도록 고정 */
  }

  label {
    display: flex;
    flex-direction: column; /* 텍스트와 설명을 수직 정렬 */
  }

  &:hover {
    background-color: #f3f3f3;
  }

  &.selected {
    border-color: #13a872;
    background-color: #e9f8f3;
  }
`;


const ProductDescription = styled.div`
  font-size: 14px;
  color: #555;
  margin-top: 10px;

  .split-text {
    display: flex;
    justify-content: space-between;
  }

  .line-break {
    margin-bottom: 5px;
  }
`;

const GiftButton = styled.button`
  background-color: #13a872;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  margin-left: auto;


  &:hover {
    background-color: #0f8f68;
  }
`;

const LoadingMessage = styled.p`
  font-size: 18px;
  text-align: center;
`;
