import { callApi } from './callApi';

// 유저 리스트
export const getPetstivalList = async (
  // userStatus: string,
  // paid: string,
  limit: number,
  currentPage: number
) => {
  const res = await callApi({
    url: `/admin/petstival/list?currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 펫스티벌 상세
export const getPetstivalDetail = async (petstivalId: number) => {
  const res = await callApi({
    url: `/admin/petstival/${petstivalId}`,
    method: 'GET',
  });
  return res;
};

export const patchPetstivalStatus = async (
  pestivalId: number,
  adminStatus: string,
  petstivalStatus: string,
) => {
  const res = await callApi({
    url: `/admin/petstival/${pestivalId}`,
    method: 'PATCH',
    data: {
      adminStatus: adminStatus,
      petstivalStatus: petstivalStatus,
    },
  });
  return res;
};

export const getPetstivalRankingList = async (
  year: number,
  month: number,
  currentPage: number,
  limit: number,
) => {
  const res = await callApi({
    url: `/admin/petstival/rankingList?currentPage=${currentPage}&limit=${limit}&year=${year}&month=${month}`,
    method: 'GET',
  });
  return res;
};
