import { EBillLogProductType, EHotelProductSaleType, EHotelProductType } from 'etc/enum';
import { callApi } from './callApi';

// 유저 리스트
export const getReservationList = async (
  hotelId: string,
  startDate: string,
  endDate: string,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/reservation/list?startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
  });
  return res;
};

export const getDisableList = async (
  hotelId: string,
  hotelProductType: EHotelProductType,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/disable-date/list?hotelProductType=${hotelProductType}`,
    method: 'GET',
  });
  return res;
};

export const patchDisableDate = async (
  hotelId: string, // 호텔 ID
  payload: {
    dateList: string[];
    hotelProductType: EHotelProductType; // 호텔 상품 타입
  }
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/disable-date`,
    method: 'PATCH',
    data: payload,
  });
  return res;
};

export const getHotelProductList = async (
  hotelId: string,
  hotelProductServiceType?: string,
) => {
  
  const queryParams = hotelProductServiceType
    ? `?hotelProductServiceType=${hotelProductServiceType}`
    : '';

  const res = await callApi({
    url: `/admin/hotel/${hotelId}/product/list${queryParams}`,
    method: 'GET',
  });
  return res;
};

export const getHotelProduct = async (
  hotelId?: string,
  hotelProductId?: string,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/product/${hotelProductId}`,
    method: 'GET',
  });
  return res;
};
// export const postTravelProductCategory = async (data: I_TravelProductCategoryRegistrationForm) => {
//   const res = await callApi({
//     url: `/admin/hotel/${hotelId}/product/${hotelProductId}`,
//     method: 'POST',
//     data: data,
//   });
//   return res;
// };

export const postHotelProduct = async (
  hotelId: string, // 호텔 ID
  payload: {
    title?: string;
    description?: string;
    price?: number;
    originPrice?: number;
    hourlyPrice?: number;
    salePercent?: number;
    quantity?: number;
    validityPeriod?: number;
    hotelProductType?: string;
    hotelProductStatus?: string; 
    hotelProductServiceType: EHotelProductSaleType
  }
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/product`,
    method: 'POST',
    data: payload,
  });
  return res;
};

export const patchHotelProduct = async (
  hotelId: string, // 호텔 ID
  hotelProductId: string, // 호텔 상품 ID
  payload: {
    title?: string;
    description?: string;
    price?: number;
    originPrice?: number;
    hourlyPrice?: number;
    salePercent?: number;
    quantity?: number;
    validityPeriod?: number;
    hotelProductType?: string;
    hotelProductStatus?: string; 
    hotelProductServiceType?: EHotelProductSaleType;
  }
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/product/${hotelProductId}`,
    method: 'PATCH',
    data: payload,
  });
  return res;
};
export const getHotelBillLogList = async (
  hotelId: string,
  currentPage: number,
  limit: number,
  productType: EBillLogProductType
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/bill-log/list?currentPage=${currentPage}&limit=${limit}&productType=${productType}`,
    method: 'GET',
  });
  return res;
};

export const getHotelBillLogDetail = async (
  hotelId: string,
  billLogId: string,
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/bill-log/${billLogId}`,
    method: 'GET',
  });
  return res;
};

export const postHotelProductGift = async (
  hotelId: string, // 호텔 ID
  payload: {
    hotelProductId?: string,
    userId?: string,
  }
) => {
  const res = await callApi({
    url: `/admin/hotel/${hotelId}/product-gift`,
    method: 'POST',
    data: payload,
  });
  return res;
};