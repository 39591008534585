export enum EEnableStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}
export enum EActiveStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}
export enum ERecommendStatus {
  RECOMMEND = 'RECOMMEND',
  NOT_RECOMMEND = 'NOT_RECOMMEND',
}
export enum EBooleanStatus {
  TRUE = 'true',
  FALSE = 'false',
}
export enum EHotelProductType {
  NULL = "",  // 회원권 호텔 상품
  HOTELING = 'HOTELING',  // 회원권 호텔 상품
  DAYCARE = 'DAYCARE',    // 회원권 유치원 상품
  TIMECARE = 'TIMECARE',      // 시간권 상품
  ONEDAY = 'ONEDAY',      // 1일권 유치원 상품
  ONEDAY_HOTELING = 'ONEDAY_HOTELING',      // 1일권 호텔링 상품
}

export enum EHotelProductStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE'
}
export enum EBillLogProductType {
  HOTEL_MEMBERSHIP= 'HOTEL_MEMBERSHIP',
  HOTEL_ONEDAY= 'HOTEL_ONEDAY',
}
export enum EHotelProductSaleType {
  SALE = 'SALE',
  SERVICE = 'SERVICE',
}