import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';

import MainLayout from "components/Layout/MainLayout";
import Spinner from "components/Spinner/Spinner";
import StatusButton from "components/Button/StatusButton";
import useLogout from 'hooks/useLogout';

import { I_BillLog } from "etc/types";
import { getHotelBillLogList } from "api/hotel";
import { EBillLogProductType, EHotelProductType } from "etc/enum";
import EditButton from "components/Button/DetailButton";
import PagenationButton from "components/Button/PagenationButton";

function HotelBillLogOneday() {

  const navigate = useNavigate();
  const { hotelId } = useParams<{ hotelId : string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isList, setList] = useState<I_BillLog[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isTotalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [isCurrentPage, setCurrentPage] = useState<number>(Number(searchParams.get("currentPage")) || 1); 
  const [ isLimit, setLimit] = useState<number>(Number(searchParams.get("limit")) || 10); 
  
  useEffect(() => {
    getAsyncList();
  }, [isCurrentPage, isLimit]);

  const getAsyncList = async () => {
    if (!hotelId) {
      console.error("Hotel ID is missing");
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const data = await getHotelBillLogList(hotelId, isCurrentPage, isLimit, EBillLogProductType.HOTEL_ONEDAY);
      if (data.ok) {
        setList(data.res.list);
        setTotalCount(data.res.totalCount);
      } else {
        alert("Failed to fetch bill logs");
      }
    } catch (error) {
      console.error("Error fetching bill logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusToggle = (id: number, currentStatus: string) => {
    const newStatus = currentStatus === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
    console.log(`Toggling status for ID: ${id} to ${newStatus}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ currentPage: String(page), limit: String(isLimit) });
  };

  const handleEdit = (billLogId: number) => {
    navigate(`/hotel/${hotelId}/oneday-log/${billLogId}`); // 수정 페이지로 이동
  };
  return (
    <MainLayout>
      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>1일권 구매 내역</Title>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>주문번호</Th>
                <Th>회원번호/이름</Th>
                <Th>구매상품</Th>
                <Th>예약일</Th>
                <Th>실구매가격(상품가격, 구매시 사용포인트)</Th>
                <Th>회원권타입(호텔링/유치원)</Th>
                <Th>사용티켓(사용수/구매수)</Th>
                <Th>회원권상태</Th>
                {/* <Th>결제타입(카드)</Th> */}
                <Th>구매일</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {isList.map((billLog) => (
                <Tr key={billLog.id}>
                  <Td>{billLog.id}</Td>
                  <Td>{billLog.orderNumber}</Td>
                  <Td>{billLog.user.id}/{billLog.user.name}</Td>
                  <Td>{billLog.billLogHotelTicket[0]?.hotelProduct.title} 이용권</Td>
                  <Td>{ billLog.billLogHotelTicket[0]?.hotelReservations[0]?.startDate } 일 { billLog.billLogHotelTicket[0]?.hotelReservations[0]?.checkInTime } ~ { billLog.billLogHotelTicket[0]?.hotelReservations[0]?.checkOutTime }</Td>
                  <Td>
                    {billLog.totalProductPrice.toLocaleString()}원
                    ({billLog.totalPointDiscount.toLocaleString()}포인트 사용)
                  </Td>
                  <Td>
                  { billLog.billLogHotelTicket[0]?.hotelProductType === EHotelProductType.TIMECARE && "시간권" }
                  { billLog.billLogHotelTicket[0]?.hotelProductType === EHotelProductType.ONEDAY && "1일권 유치원" }
                  { billLog.billLogHotelTicket[0]?.hotelProductType === EHotelProductType.ONEDAY_HOTELING && "1일권 호텔링" }
                  </Td>
                  <Td>
                    {billLog.billLogHotelTicket.filter((ticket) => ticket.orderStatus.value === "구매완료").length}
                    /
                    {billLog.billLogHotelTicket.length}
                  </Td>
                  <Td>
                    <TdStatus 
                      status={billLog.billLogHotelTicket.some(ticket => ticket.orderStatus.value === "취소완료") ? "취소완료" :
                      billLog.billLogHotelTicket.some(ticket => ticket.orderStatus.value === "구매완료") ? "구매완료" : "사용완료"}>
                    {
                      billLog.billLogHotelTicket.some(ticket => ticket.orderStatus.value === "취소완료") ? "취소완료" :
                      billLog.billLogHotelTicket.some(ticket => ticket.orderStatus.value === "구매완료") ? "구매완료" : '사용완료'
                    }
                  </TdStatus>
                  </Td>
                  {/* <Td>{billLog.paymentMethod}</Td> */}
                  <Td>{new Date(billLog.createdAt).toLocaleDateString("ko-KR")}</Td>
                  <Td><EditButton onClick={() => handleEdit(billLog.id)}>상세보기</EditButton></Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
      <PagenationButton
          activePage={isCurrentPage}
          totalCount={isTotalCount}
          postPerPage={isLimit}
          handlePageChange={handlePageChange}
        />
    </MainLayout>
  );
}

export default HotelBillLogOneday;

// Styled Components
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left;
`;

const Tr = styled.tr<{ subrow?: boolean }>`
  background-color: ${(props) => (props.subrow ? "#f9f9f9" : "white")};
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const SubRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
`;

const TdStatus = styled.td<{ status?: string }>`
  color: ${({ status }) =>
    status === "구매완료" ? "#28a745" :
    status === "취소완료" ? "#dc3545" :
    status === "사용완료" ? "#5c23df" :
    "#333"};
  font-weight: bold;
`;
