import styled from 'styled-components';

interface I_StatusButton {
  children: React.ReactNode;
  onClick: () => void;
  isActive?: boolean;
  style?: React.CSSProperties;
  [key: string]: any;
}

function StatusButton({
  children,
  onClick,
  isActive,
  style,
  ...rest
}: I_StatusButton) {
  return (
    <S_StatusButton onClick={onClick} isActive={isActive} style={style} {...rest}>
      {children}
    </S_StatusButton>
  );
}

export default StatusButton;

const S_StatusButton = styled.button<{ isActive?: boolean }>`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: ${(props) => (props.isActive ? '#007BFF' : '#6C757D')}; /* 파란색(활성)과 회색(비활성) */
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`;
