import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import LongButton from 'components/Button/LongButton';
import NormalModal from 'components/Modal/NormalModal';

import { getInquireDetail, postInquireStatus } from 'api/inquire';
import { dateFormat } from 'utils/common';
import useLogout from 'hooks/useLogout';

interface I_AppServiceImage {
  id: number;
  image: {
    id: number;
    url: string;
  };
}

interface I_AppServiceDetail {
  id: number;
  createdAt: string;
  email: string;
  title: string;
  content: string;
  userCode: string;
  nickname: string;
  status: string;
  answer: string;
  userId: number;
  images: I_AppServiceImage[];
}

function AppServiceDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [inquire, setInquire] = useState<I_AppServiceDetail>({
    id: 0,
    createdAt: '',
    email: '',
    title: '',
    content: '',
    userCode: '',
    nickname: '',
    status: '',
    answer: '',
    userId: 0,
    images: [{ id: 0, image: { id: 0, url: '' } }],
  });
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isAnswer, setAnswer] = useState<string>('');
  const [isUserId, setUserId] = useState<string>('');

  const onClickImage = (url: string) => {
    setIsImageModal(true);
    setImageUrl(url);
  };

  const onClickAnswerCompleted = async () => {
    const data = await postInquireStatus(Number(id), '답변 완료', isAnswer, isUserId);

    if (data.ok) {
      alert('답변이 변경되었습니다.');
      navigate(`/service/app`);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncInquireDetail = async () => {
    const data = await getInquireDetail(Number(id));

    if (data.ok) {
      setInquire(data.res);
      setAnswer(data.res.answer);
      setUserId(data.res.userId)
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncInquireDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>문의 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>문의 일시</th>
              <td>
                <DisabledInput
                  text={dateFormat(inquire?.createdAt).format(
                    'YYYY년 MM월 DD일 HH:mm'
                  )}
                />
              </td>
              <th>유저 닉네임</th>
              <td>
                <DisabledInput text={inquire?.nickname} />
              </td>
            </tr>
            <tr>
              <th>답변 이메일</th>
              <td>
                <DisabledInput text={inquire?.email} />
              </td>
              <th>User Code</th>
              <td
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate(`/user/${inquire.userId}`)}
              >
                <DisabledInput text={inquire?.userCode} />
              </td>
            </tr>
            <tr>
              <th>답변 현황</th>
              <td>
                <DisabledInput
                  text={inquire?.status}
                  style={{
                    color:
                      inquire?.status === '답변 완료' ? '#3CBF0E' : '#FF0000',
                  }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>제목</th>
              <td colSpan={3}>
                <DisabledInput
                  text={inquire?.title}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 내용</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={inquire?.content}
                  style={{ height: 127 }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>참조 이미지</th>
              <td colSpan={3}>
                <S_ImgBox>
                  <p>
                    {inquire.images.length == 0 && '등록된 이미지가 없습니다.'}
                  </p>
                  {inquire.images.map((v: I_AppServiceImage, i: number) => (
                    <S_ImgItem
                      key={i}
                      onClick={() => onClickImage(v.image.url)}
                    >
                      <S_Img src={v.image.url} />
                    </S_ImgItem>
                  ))}
                </S_ImgBox>
              </td>
            </tr>
            <tr>
              <th className='alignTop'>답변 입력</th>
              <td colSpan={3}>
                <S_TextArea
                  value={isAnswer}
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder='답변 내용을 입력하세요.'
                  disabled={!!inquire.answer}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      {inquire?.status !== '답변 완료' && (
        <S_ButtonBox>
          <LongButton onClick={() => onClickAnswerCompleted()}>
            답변 완료
          </LongButton>
        </S_ButtonBox>
      )}

      {isImageModal && (
        <NormalModal
          state={isImageModal}
          setState={setIsImageModal}
          title='참조 이미지'
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default AppServiceDetail;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_ModalContent = styled.div`
  padding: 13px 50px 60px;
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;

const S_Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const S_ImgItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
`;

const S_ImgBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  p {
    padding-top: 14px;
    font-size: 12px;
  }
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      min-width: 85px;
    }
    &:nth-child(3) {
      min-width: 120px;
      padding-left: 20px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:last-child {
    border-bottom: 0;
  }

  td {
    min-width: 380px;
    width: 380px;
  }
`;

const S_TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 4px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.bg};
  resize: none;
`;
