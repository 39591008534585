import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import MainLayout from "components/Layout/MainLayout";
import Spinner from "components/Spinner/Spinner";
import { I_HotelProduct } from "etc/types";
import { postHotelProduct } from "api/hotel";
import { EHotelProductSaleType, EHotelProductType } from "etc/enum";

const HotelProductRegister: React.FC = () => {
  const { hotelId } = useParams<{ hotelId: string }>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<I_HotelProduct>({
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      price: 0,
      originPrice: 0,
      hourlyPrice: 0,
      salePercent: 0,
      quantity: 1,
      validityPeriod: 0,
      hotelProductStatus: "",
      hotelProductType: EHotelProductType.DAYCARE,
      hotelProductServiceType: EHotelProductSaleType.SALE
    },
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (formData: I_HotelProduct) => {
    if (!hotelId) {
      alert("잘못된 접근입니다.");
      return;
    }

    const payload = {
      title: formData.title,
      description: formData.description,
      price: formData.price,
      originPrice: formData.originPrice,
      hourlyPrice: formData.hourlyPrice,
      salePercent: formData.salePercent,
      quantity: formData.quantity,
      validityPeriod: formData.validityPeriod,
      hotelProductType: formData.hotelProductType,
      hotelProductStatus: formData.hotelProductStatus,
      hotelProductServiceType: formData.hotelProductServiceType as EHotelProductSaleType,
    };

    console.log('payload: ', payload)

    setLoading(true);

    try {

      const response = await postHotelProduct(hotelId, payload);
      if (response.ok) {
        alert("상품이 성공적으로 등록되었습니다.");
        navigate(`/hotel/${hotelId}/product/list`);
      } else {
        alert("상품 등록에 실패했습니다. 다시 시도해주세요.");
        console.error(response.res.error.message);
      }

    } catch (error) {
      console.error("Error creating product:", error);
      alert("서버 오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Title>호텔 상품 등록</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>상품명</Label>
              <Input {...register("title", { required: "상품명은 필수 항목입니다." })} />
              {errors.title && <ErrorText>{errors.title.message}</ErrorText>}
            </FormGroup>

            <FormGroup>
              <Label>상품 설명</Label>
              <TextArea {...register("description")} rows={4} />
            </FormGroup>

            <FormGroup>
              <Label>가격</Label>
              <Input type="number" {...register("price", { required: "가격은 필수 항목입니다." })} />
              {errors.price && <ErrorText>{errors.price.message}</ErrorText>}
            </FormGroup>

            <FormGroup>
              <Label>원가</Label>
              <Input type="number" {...register("originPrice")} />
            </FormGroup>

            <FormGroup>
              <Label>시간당 가격</Label>
              <Input type="number" {...register("hourlyPrice")} />
            </FormGroup>

            <FormGroup>
              <Label>할인율</Label>
              <Input type="number" {...register("salePercent")} />
            </FormGroup>

            <FormGroup>
              <Label>수량</Label>
              <Input type="number" {...register("quantity", { required: "수량은 필수 항목입니다." })} />
              {errors.quantity && <ErrorText>{errors.quantity.message}</ErrorText>}
            </FormGroup>

            <FormGroup>
              <Label>유효기간</Label>
              <Input
                type="text"
                value={
                  watch("validityPeriod") ? `${watch("validityPeriod")}일` : ""
                }
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // 숫자만 입력
                  setValue("validityPeriod", value ? parseInt(value, 10) : undefined); // 숫자로 변환해서 설정
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label>상품 유형</Label>
              <Select {...register("hotelProductType", { required: "상품 유형은 필수 항목입니다." })}>
                <option value="">선택</option>
                <option value="HOTELING">회원권 호텔 상품</option>
                <option value="DAYCARE">회원권 유치원 상품</option>
                <option value="TIMECARE">시간권 상품</option>
                <option value="ONEDAY">1일권 유치원 상품</option>
                <option value="ONEDAY_HOTELING">1일권 호텔링 상품</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>상품 상태</Label>
              <Select {...register("hotelProductStatus", { required: "앱 노출여부는 필수 항목입니다." })}>
                <option value="">선택</option>
                <option value="ACTIVE">노출</option>
                <option value="DEACTIVE">비노출</option>
              </Select>
              {errors.hotelProductStatus && <ErrorText>{errors.hotelProductStatus.message}</ErrorText>}
            </FormGroup>

            <FormGroup>
              <Label>서비스 타입</Label>
              <Select {...register("hotelProductServiceType", { required: "서비스 타입은 필수 항목입니다." })}>
                <option value="">선택</option>
                <option value="SALE">판매상품</option>
                <option value="SERVICE">서비스상품</option>
              </Select>
              {errors.hotelProductServiceType && <ErrorText>{errors.hotelProductServiceType.message}</ErrorText>}
            </FormGroup>

            <ButtonGroup>
              <CancelButton onClick={() => navigate(`/hotel/${hotelId}/product/list`)}>취소</CancelButton>
              <SubmitButton type="submit">등록</SubmitButton>
            </ButtonGroup>
          </Form>
        </Container>
      )}
    </MainLayout>
  );
};

export default HotelProductRegister;

// Styled Components
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr; /* 두 개의 동일한 컬럼 */
  gap: 20px; /* 폼 요소 사이의 간격 */
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  grid-column: span 2; /* 버튼 그룹이 전체 너비를 차지하도록 설정 */
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.colors.ho1};
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.ho2};
  }
`;

const CancelButton = styled.button`
  background-color: #ddd;
  color: #000;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #bbb;
  }
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.ho6};
  font-size: 12px;
`;
